import React from "react";
import LiquidDripCanvas from "./components/animatedBackground";
import AnimatedForm from "./components/animatedForm";
import Header from "./components/header";
import "bootstrap/dist/css/bootstrap.min.css";
const App = () => {
  return (
    <div className="parent-container">
      <AnimatedForm />
      <div className="bottom-right-message">
        Note: www.upgradekiyakya.com is the contest page of Shalimar paints ltd.
      </div>
    </div>
  );
};

export default App;
