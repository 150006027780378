import React from "react";
import "./RotatingDots.css"; // You can either use this or inline styles

const RingOfStars = () => {
  return (
    <div className="ring-of-stars-container">
      <div className="ring-of-stars" style={{ "--primary": "#FF0000" }}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default RingOfStars;
