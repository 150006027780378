import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import backgroundImage from "../pictures/bg5.jpg";
import { Row, Col, Alert, Modal, ModalBody } from "reactstrap";
import Terms from "./termsFile";
import RotatingDots from "./loader";

const questions = [
  { question: "Name", type: "input", validation: "name" },
  { question: "Contact Number", type: "input", validation: "phone" },
  { question: "Mail ID", type: "input", validation: "email" },
  { question: "City", type: "input" },
  {
    question: "What is your area pincode?",
    type: "input",
    validation: "pincode",
  },
  {
    question: "What type of Home do you own?",
    type: "radio",
    options: ["Flat", "Villa", "Farm House/Other"],
  },
  {
    question: "When did you paint it Last?",
    type: "radio",
    options: [
      "1 year back",
      "3 years back",
      "5 years back",
      "More than 5 years ",
    ],
  },
  {
    question: "Do you want your home to be painted?",
    type: "radio",
    options: ["Yes", "No"],
  },
  {
    question:
      "Do you want our Paint consultant to guide you with the best options?",
    type: "radio",
    options: ["Yes", "No", "Maybe"],
  },
];

const AnimatedQuizForm = () => {
  const [answers, setAnswers] = useState(Array(questions.length).fill(""));
  const [errors, setErrors] = useState(Array(questions.length).fill(""));
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [showTerm, setShowTerm] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false); // New state for checkbox
  const [alertObj, setAlertObject] = useState({
    showAlert: false,
    showAlertModal: false,
    alertContent: "",
  });
  const [finalStatus, setFinalStatus] = useState(false);

  const handleAlertClose = () => {
    setAlertObject({
      showAlert: false,
      showAlertModal: false,
      alertContent: "",
    });
  };
  const [loader, setLoader] = useState(false);

  const handleInputChange = (event, index) => {
    const newAnswers = [...answers];
    newAnswers[index] = event.target.value;
    setAnswers(newAnswers);

    // Clear error for this input
    const newErrors = [...errors];
    newErrors[index] = "";
    setErrors(newErrors);
  };

  const handleOptionChange = (event, index) => {
    const newAnswers = [...answers];
    newAnswers[index] = event.target.value;
    setAnswers(newAnswers);

    // Clear error for this option
    const newErrors = [...errors];
    newErrors[index] = "";
    setErrors(newErrors);
    // if (event.target.id === "7" && event.target.value === "No") {
    //   setQuizCompleted(true);
    // }
  };

  const handleSubmit = () => {
    // Validate answers before submission
    const newErrors = [...errors];
    let hasErrors = false;

    questions.forEach((question, index) => {
      if (!answers[index] && index !== 8 && index !== 4 && index !== 2) {
        newErrors[index] = "This question must be answered.";
        hasErrors = true;
      } else if (question.validation) {
        const validationResult = validateInput(
          answers[index],
          question.validation
        );
        if (validationResult !== true && answers[index] != "") {
          newErrors[index] = validationResult;
          hasErrors = true;
        }
      }
    });

    if (hasErrors) {
      setErrors(newErrors);
      setAlertObject({
        showAlert: true,
        showAlertModal: true,
        alertContent:
          "Please fill out all required fields correctly and ensure values meet the specified criteria.",
      });
    } else {
      if (!agreedToTerms) {
        setAlertObject({
          showAlert: true,
          showAlertModal: true,
          alertContent: "Accept the contest Terms and Conditions.",
        });
        return;
      }
      setQuizCompleted(true); // Mark quiz as completed
    }
  };

  const validateInput = (input, type) => {
    switch (type) {
      case "name":
        return (
          /^[a-zA-Z\s]{2,}$/.test(input) || "Name must be at least 2 letters."
        );
      case "email":
        return (
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(input) ||
          "Invalid email format."
        );
      case "phone":
        return /^\d{10}$/.test(input) || "Phone number must be 10 digits.";
      case "pincode":
        return /^\d{6}$/.test(input) || "Pincode must be 6 digits.";
      default:
        return true;
    }
  };

  useEffect(() => {
    if (quizCompleted) {
      setLoader(true);
      const payload = {
        Name: answers[0],
        ContactNumber: answers[1],
        MailID: answers[2],
        City: answers[3],
        PinCode: answers[4],
        Question1Answer: answers[5],
        Question2Answer: answers[6],
        Question3Answer: answers[7],
        Question4Answer: answers[8],
      };
      fetch("https://shalimarsalesforceapi.mayanksoftwares.co/Api/Quiz", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.Status == true) {
            setFinalStatus(true);
          } else {
            setFinalStatus(false);
          }
          setLoader(false);
        })
        .catch((err) => console.log(err));
    }
  }, [quizCompleted]);

  return (
    <div className="form-container">
      {loader && <RotatingDots />}
      <Row style={{ marginBottom: "20px", justifyContent: "center" }}>
        <Col>
          {" "}
          {/* Limit the column width to 60% */}
          <img
            src={backgroundImage}
            alt="Decorative"
            style={{
              width: "100%", // Set width to 100% of the column
              height: "auto", // Allow height to adjust based on the width
              minHeight: "100%", // Set a maximum height for the banner effect
              objectFit: "cover", // Ensure the image covers the area without distortion
              display: "block", // Remove any inline spacing
            }}
          />
        </Col>
      </Row>
      <Modal
        isOpen={alertObj.showAlertModal}
        toggle={handleAlertClose}
        centered
      >
        <ModalBody>
          <Alert
            color="danger"
            toggle={handleAlertClose}
            isOpen={alertObj.showAlert}
          >
            {alertObj.alertContent}
          </Alert>
        </ModalBody>
      </Modal>

      <Row className="formRow">
        {quizCompleted && !loader ? (
          <motion.div
            className="thank-you-message"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            {finalStatus ? (
              <>
                {" "}
                <h3 style={{ color: "green" }}>
                  Thank you for participating in the contest!
                </h3>
                <p>Your answers have been recorded.</p>
              </>
            ) : (
              <h3 style={{ color: "red" }}>Form Already Submitted</h3>
            )}
          </motion.div>
        ) : (
          <motion.div
            className="all-questions"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            {questions.map((q, index) => {
              return (
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  style={{ marginTop: "10px" }}
                  key={index}
                >
                  <div className="question-container" style={{ width: "100%" }}>
                    <label
                      className="question-label"
                      style={{
                        display:
                          index == 8 && answers[7] !== "Yes" ? "none" : "",
                      }}
                    >
                      {q.question}
                    </label>
                    {q.type === "input" ? (
                      <div className="input-wrapper">
                        <input
                          id={index}
                          type={q.validation === "phone" ? "number" : "text"}
                          className="input-field"
                          value={answers[index]}
                          onChange={(event) => handleInputChange(event, index)}
                        />
                        {errors[index] && (
                          <div className="error-message">{errors[index]}</div>
                        )}
                      </div>
                    ) : (
                      <div
                        className="options-wrapper"
                        style={{
                          display:
                            index == 8 && answers[7] !== "Yes" ? "none" : "",
                        }}
                      >
                        {q.options.map((option, i) => (
                          <label key={i} className="option">
                            <input
                              id={index}
                              type="radio"
                              value={option}
                              checked={answers[index] === option}
                              onChange={(event) =>
                                handleOptionChange(event, index)
                              }
                            />
                            {option}
                          </label>
                        ))}
                        {errors[index] && (
                          <div className="error-message">{errors[index]}</div>
                        )}
                      </div>
                    )}
                  </div>
                </Col>
              );
              // }
              // return null;
            })}

            <div className="terms-and-submit-container">
              <div className="terms-and-conditions">
                <input
                  type="checkbox"
                  checked={agreedToTerms}
                  onChange={(e) => setAgreedToTerms(e.target.checked)}
                />
                <label>
                  {"Accept the contest "}
                  <span
                    onClick={() => setShowTerm(true)}
                    style={{
                      textDecoration: "underline",
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    {"Terms and Conditions"}
                  </span>
                </label>
              </div>
              <div className="submit-button-wrapper">
                <button className="next-button" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </Row>
      <Terms showTerm={showTerm} toggle={() => setShowTerm(false)} />
    </div>
  );
};

export default AnimatedQuizForm;
