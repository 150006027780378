import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import backgroundImage from "../pictures/bg5.jpg";
const Terms = ({ showTerm, toggle }) => {
  return (
    <>
      <Modal isOpen={showTerm} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          Shalimar Paints "Upgrade Kiya - Lucky Draw Contest" Terms and
          Conditions
        </ModalHeader>
        <ModalBody>
          <img
            src={backgroundImage}
            alt="Decorative"
            style={{
              width: "100%", // Set width to 100% of the column
              height: "auto", // Allow height to adjust based on the width
              maxHeight: "50%", // Set a maximum height for the banner effect
              objectFit: "cover", // Ensure the image covers the area without distortion
              display: "block",
              paddingTop: "0px",
              paddingRight: "20px",
              paddingLeft: "20px",
              paddingBottom: "10px",
            }}
          />
          <h6>Name</h6>
          <p>This question must be answered.</p>

          <p>
            <strong>Contact Number</strong>
          </p>
          <p>This question must be answered.</p>

          <p>
            <strong>Mail ID</strong>
          </p>

          <p>
            <strong>City</strong>
          </p>
          <p>This question must be answered.</p>

          <p>
            <strong>What is your area pincode?</strong>
          </p>

          <p>
            <strong>What type of Home do you own?</strong>
          </p>
          <p>Flat / Villa / Farm House / Other</p>
          <p>This question must be answered.</p>

          <p>
            <strong>When did you paint it Last?</strong>
          </p>
          <p>1 year back / 3 years back / 5 years back / More than 5 years</p>
          <p>This question must be answered.</p>

          <p>
            <strong>Do you want your home to be painted?</strong>
          </p>
          <p>Yes / No</p>

          <p>
            <strong>
              Do you want our Paint consultant to guide you with the best
              options?
            </strong>
          </p>
          <p>Yes / No / Maybe</p>

          <p>
            <strong>Accept the contest Terms and Conditions</strong>
          </p>

          <h6>
            Shalimar Paints "Upgrade Kiya - Lucky Draw Contest" Terms and
            Conditions
          </h6>
          <h5>
            Please read the following terms and conditions for the Shalimar
            Paints Upgrade Kiya - Lucky Draw Contest ("Contest")
          </h5>
          <h6>1. Eligibility</h6>
          <p>
            - The Contest is open to all individuals residing in India who own a
            house in their or their spouse's name.
          </p>
          <p>
            - The participant must be 18 years or older and must provide valid{" "}
            <strong>KYC documents</strong> (such as Aadhaar card, PAN card,
            passport, or similar) to verify ownership.
          </p>
          <p>
            - Employees of Shalimar Paints and their immediate family members
            are not eligible to participate.
          </p>
          <h6>2. Contest Period</h6>
          <p>- The Contest will run from 30.09.2024 to 30.10.2024.</p>
          <p>
            - Entries received after the Contest period will not be considered.
          </p>
          <h6>3. How to Participate</h6>
          <p>- To enter the Contest, participants must:</p>
          <p> 1. Fill out a simple brand quiz provided by Shalimar Paints.</p>
          <p>
            {" "}
            2. Submit accurate and complete details as required in the quiz
            form.
          </p>
          <p>- Only one entry per household is allowed.</p>
          <h6>4. Selection of Winners</h6>
          <p>
            - Upto Five (5) lucky winners* will be selected through a random
            draw from all eligible participants.
          </p>
          <p>
            - The decision of Shalimar Paints will be final, and no
            correspondence regarding the selection of winners will be
            entertained.
          </p>
          <h6>5. Prizes</h6>
          <p>
            - Each winner will receive free paint from Shalimar Paints, which
            includes: Interior Emulsion and Wall Primer for up to a 3 BHK house
            configuration (For Value upto Rs 50,000 MRP).
          </p>
          <p>
            - Any additional costs (e.g. labor, putty, surface preparation,
            etc.) will be borne by the customer.
          </p>
          <h6>6. Prize Fulfilment</h6>
          <p>
            - A Shalimar Paints Business Development Officer will visit/call the
            winner’s house to assess the painting requirements.
          </p>
          <p>
            - The prize is applicable to the winner's primary residence, which
            must be owned by the participant or their spouse.
          </p>
          <p>
            - Winners must provide valid KYC documents and proof of ownership
            (in the name of the participant or their spouse) before claiming the
            prize.
          </p>
          <p>
            - Shalimar Paints reserves the right to verify the participant's
            eligibility and disqualify any entry at its discretion.
          </p>
          <p>
            - Winners are responsible for arranging any additional services or
            products not covered under the prize.
          </p>
          <h6>7. Exclusions</h6>
          <p>
            - The Contest prize includes only Interior Emulsion and Wall Primer
            for up to a 3 BHK house. Any other costs such as labor, putty, or
            extra paint beyond the coverage limit must be paid for by the
            winner.
          </p>
          <p>
            - Shalimar Paints will not cover additional services like exterior
            painting, structural repairs, or decor upgrades.
          </p>
          <h6>8. Publicity</h6>
          <p>
            - By participating, you agree that Shalimar Paints may use your
            name, photograph, and details about your house for promotional
            purposes, without any further compensation.
          </p>
          <h6>9. Liability</h6>
          <p>
            - Shalimar Paints will not be responsible for any delay, damage, or
            loss arising during the painting process.
          </p>
          <p>
            - Any disputes or claims related to the Contest will be subject to
            the jurisdiction of the courts in New Delhi.
          </p>
          <h6>10. General Conditions</h6>
          <p>
            - Shalimar Paints reserves the right to amend or modify these terms
            and conditions at any time without prior notice.
          </p>
          <p>
            - Participation in the Contest is deemed acceptance of these terms
            and conditions.
          </p>
          <h6>11. Geographical Restrictions</h6>
          <p>
            - The Contest is only applicable to customers residing in the Delhi
            NCR region, which includes Delhi, Faridabad, Gurgaon, Noida, and
            Ghaziabad.
          </p>
          <p>
            - Entries from participants outside these regions will not be
            considered for the lucky draw.
          </p>
          <p>
            By entering the Contest, you agree to these terms and conditions and
            to comply with any decisions made by Shalimar Paints.
          </p>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Terms;
